[dir=rtl]{
  .sidebar{
    right: 0;
    left: unset;
  }
  .sidebar-nav{
    padding-right: 0;

    .nav-link i{
      margin-right: unset;
      margin-left: 10px;
    }
  }

  @media (max-width: 1199px) {
    .sidebar {
      right: -300px;
      left: 0;
    }
  }

  @media (min-width: 1200px) {
    #main, #footer {
      margin-right: 300px;
      margin-left: unset;
    }
  }
  @media (max-width: 1199px) {
    .toggle-sidebar .sidebar {
      right: 0;
    }
  }
  @media (min-width: 1200px) {
    .toggle-sidebar #main, .toggle-sidebar #footer {
      margin-right: 0;
    }
    .toggle-sidebar .sidebar {
      right: -300px;
      left: unset;
    }
  }

  .logo img{
    margin-left: 6px;
    margin-right: unset;
  }
  .ms-auto{
    margin-right: auto;
    margin-left: unset !important;
  }
  .header-nav .profile .dropdown-item i{
    margin-left: 10px;
    margin-right: unset;
  }
  .dropdown-toggle::after{
    margin-left: unset;
    margin-right: 0.255rem;
  }
  @media (min-width: 768px) {
    .dropdown-menu-arrow::before {
      right: unset;
      left: 20px;
    }
  }
  .breadcrumb-item+.breadcrumb-item::before{
    float: right;
    padding-left: var(--bs-breadcrumb-item-padding-x);
    padding-right: unset;
  }
  .breadcrumb-item+.breadcrumb-item{
    padding-right: var(--bs-breadcrumb-item-padding-x);
    padding-left: unset;
  }
  .form-switch{
    padding-right: 3.5rem;
    padding-left: unset;
    .form-check-input{
      width: 3rem;
      margin-right: -3.5rem;
      margin-left: unset;
      height: 1.5rem;
      margin-top: 0;
    }
  }
}
